/* eslint-disable react/no-array-index-key */
// the above works because we never change array index data on mobile/desktop nav
import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import classNames from 'classnames';

import { useIsTablet } from '../../utils/breakpoints';
import { ReactComponent as HamburgerIcon } from '../../svgs/icons/hamburger.svg';
import { ReactComponent as CloseIcon } from '../../svgs/icons/times.svg';
import { ReactComponent as ChevronLeftIcon } from '../../svgs/icons/chevron-left.svg';
import { ReactComponent as ChevronRightIcon } from '../../svgs/icons/chevron-right.svg';
import { ReactComponent as ArrowRightIcon } from '../../svgs/icons/arrow-right.svg';

import Icon from '../GenericIcon';
import mobileNavData from '../../../site-data/common/nav/mobile';
import {
	NavSearch,
	NavSearchContainer,
	NavContainer,
	NavDirectLink,
	HostJaneLogoLink,
	NavDirectLinkGroup,
	NavDirectLinkWithChildren,
} from './common';

const MobileNavHeader = styled.header`
	${NavContainer} {
		border-bottom: 1px solid #e2e2e2;
		height: ${props =>
			props.isTablet
				? props.theme.siteApp.navHeight.tablet
				: props.theme.siteApp.navHeight.mobile}px;
		padding: 0
			${props =>
				props.isTablet
					? props.theme.gutter.small
					: props.theme.gutter.small / 2}px;

		.hostjane-nav__menutoggle {
			cursor: pointer;
			height: ${props =>
				props.isTablet
					? props.theme.siteApp.navHeight.tablet
					: props.theme.siteApp.navHeight.mobile}px;
			width: 32px;
			font-size: 22px;
			color: #595959;
			padding: 0;
			background: transparent;
			border: 0 none;
			outline: none;
			display: flex;
			align-items: center;
			justify-content: center;
		}

		.hostjane-nav__logo {
			width: 225px;
			height: 23px;

			@media only screen and (max-width: 576px){
				width: 160px;
				height: 23px;
			}
		}

		.hostjane-nav__links {
			margin-left: auto;
		}
	}

	${NavSearchContainer} {
		width: 100%;
		padding: ${props => props.theme.gutter.small / 2}px
			${props =>
				props.isTablet
					? props.theme.gutter.small
					: props.theme.gutter.small / 2}px;
		padding-bottom: 0px;
	}
`;

const SmallNavigationPopupContainer = styled.div`
	position: fixed;
	z-index: 10000;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	overflow: hidden;
	will-change: background-color;
	transition: background-color ${props => props.theme.siteApp.transition};

	.hostjane-mobile-popup__inner {
		position: absolute;
		height: 100%;
		top: ${props => (props.isTablet ? '0' : '42px')};
		width: ${props => (props.isTablet ? '320px' : '100%')};
		left: 0;
		overflow: hidden;
		background-color: #fff;
		transition: transform ${props => props.theme.siteApp.transition},
			opacity ${props => props.theme.siteApp.transition};
		padding: 0;
	}

	/** Transitions */
	&.hostjane-mobile-popup-enter {
		background-color: rgba(0, 0, 0, 0);
		.hostjane-mobile-popup__inner {
			transform: ${props =>
				props.isTablet ? 'translateX(-320px)' : 'translateY(100vh)'};
			opacity: 0;
		}
	}
	&.hostjane-mobile-popup-enter-active {
		background-color: rgba(0, 0, 0, 0.5);
		.hostjane-mobile-popup__inner {
			transform: ${props =>
				props.isTablet ? 'translateX(0)' : 'translateY(0)'};
			opacity: 1;
		}
	}
	&.hostjane-mobile-popup-exit {
		background-color: rgba(0, 0, 0, 0.5);
		.hostjane-mobile-popup__inner {
			transform: ${props =>
				props.isTablet ? 'translateX(0)' : 'translateY(0)'};
			opacity: 1;
		}
	}
	&.hostjane-mobile-popup-exit-active {
		background-color: rgba(0, 0, 0, 0);
		.hostjane-mobile-popup__inner {
			transform: ${props =>
				props.isTablet ? 'translateX(-320px)' : 'translateY(100vh)'};
			opacity: 0;
		}
	}

	/** Header */
	.hostjane-mobile-popup__header {
		display: flex;
		flex-flow: row nowrap;
		align-items: ${props => (props.isTablet ? 'flex-start' : 'center')};
		padding: ${props => props.theme.gutter.small / 2}px;
	}
	.hostjane-mobile-popup__logo {
		width: 225px;
		flex: 0 0 225px;
	}
	.hostjane-mobile-popup__close,
	.hostjane-mobile-popup__menunavback {
		width: 32px;
		height: 32px;
		flex: 0 0 32px;
		margin-left: auto;
		font-size: 24px;
		color: ${props => props.theme.siteApp.navSearch.color};
		transition: color ${props => props.theme.siteApp.transition};
		will-change: color;
		background: transparent;
		border: 0 none;
		outline: none;
		padding: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;

		&:hover {
			color: ${props => props.theme.siteApp.primaryHoverColor};
		}
	}
	.hostjane-mobile-popup__menunavback {
		margin-left: 0;
		margin-right: ${props => props.theme.gutter.small / 2}px;
	}
	.hostjane-mobile-popup__browse {
		font-size: 18px;
		text-transform: uppercase;
		color: #222;
		font-weight: bold;
		letter-spacing: 1.5px;
	}
	.hostjane-mobile-popup__menunavback + .hostjane-mobile-popup__browse {
		margin-left: auto;
		margin-right: auto;
		+ .hostjane-mobile-popup__close {
			margin-left: 0;
		}
	}
	.hostjane-mobile-popup__tabletpager {
		margin: ${props => props.theme.gutter.small / 2}px 0;
		padding: 0 ${props => props.theme.gutter.small / 2}px;
		display: flex;
		flex-flow: row nowrap;
		align-items: center;
		height: 32px;
		.hostjane-mobile-popup__menunavback {
			margin-left: ${props => props.theme.gutter.small / -2}px;
		}
		.hostjane-mobile-popup__menunavback + .hostjane-mobile-popup__browse {
			margin-left: 0;
			margin-right: 0;
		}
	}

	/** Menu Items */
	.hostjane-mobile-popup__menu {
		position: relative;
		height: calc(
			100% - ${props => (props.isTablet ? '115px' : `${47 + 42}px`)}
		);
		overflow-y: auto;
	}
	.hostjane-mobile-popup__list {
		position: absolute;
		top: 0;
		left: 0;

		padding-bottom: 30px;
		width: 100%;
		min-height: 100%;
		transition: transform ${props => props.theme.siteApp.transition},
			opacity ${props => props.theme.siteApp.transition};
		transform: translateX(0%);
		will-change: transform, opacity;

		display: flex;
		flex-flow: column nowrap;
		> * {
			flex: 0 0 auto;
		}

		&.hostjane-mobile-popup__list-enter {
			transform: translateX(
				${props => (props.goingBack ? '-100%' : '100%')}
			);
			z-index: 2;
			opacity: 0.5;
		}
		&.hostjane-mobile-popup__list-enter-active {
			transform: translateX(0%);
			z-index: 2;
			opacity: 1;
		}
		&.hostjane-mobile-popup__list-exit {
			transform: translateX(0%);
			z-index: 1;
			opacity: 1;
		}
		&.hostjane-mobile-popup__list-exit-active {
			z-index: 1;
			opacity: 0.5;
			transform: translateX(
				${props => (props.goingBack ? '100%' : '-100%')}
			);
		}
	}

	.hostjane-mobile-popup__listitem {
		display: flex;
		height: 50px;
		align-items: center;
		color: #111;
		cursor: pointer;
		background-color: transparent;
		border: 0 none;
		outline: none;
		padding: 0 ${props => props.theme.gutter.small / 2}px;
		font-weight: 600;
		font-size: 18px;
		width: 100%;
		text-decoration: none;
		-webkit-tap-highlight-color: ${props =>
			props.theme.siteApp.tapBackground};

		&.hostjane-mobile-popup__listitem--hasicon {
			height: 40px;
			line-height: 1;
			&:last-of-type {
				margin-bottom: 30px;
			}
		}

		&.hostjane-mobile-popup__listitem--strong {
			font-weight: bold;
			text-decoration: none;
		}

		&:hover {
			color: ${props => props.theme.siteApp.primaryColor};
			text-decoration: none;
		}

		.hostjane-mobile-popup__listitemicon {
			height: 16px;
			width: 16px;
			margin: 0 10px;
			svg {
				height: 16px;
				width: 16px;
			}
		}

		&.hostjane-mobile-popup__listitem--hasBorderTop {
			margin-top: 20px;
			position: relative;
			&::before {
				content: '';
				display: block;
				height: 1px;
				background: #d5dbdb;
				position: absolute;
				left: 10px;
				right: 10px;
				top: -10px;
			}
		}

		&.hostjane-mobile-popup__listitem--footerButton {
			background-color: #f1f3f4;
			margin: auto 20px 0;
			height: 38px;
			width: auto;
			.hostjane-mobile-popup__listitemmore {
				height: 10px;
				width: 10px;
				margin-left: 6px;
				transition: transform 0.3s;
				display: flex;
				align-items: center;
				position: relative;
				top: 1px;
				color: #0064d2;
				svg {
					height: 10px;
					width: 10px;
					fill: currentColor;
				}
			}
			&:hover,
			&:active {
				color: ${props => props.theme.siteApp.navColor};
				.hostjane-mobile-popup__listitemmore {
					transform: translateX(6px);
				}
			}
		}
	}
	.hostjane-mobile-popup__listitemnext {
		font-size: 12px;
		margin-left: auto;
		margin-right: ${props => props.theme.gutter.small / 2}px;
	}
`;

function SmallNavigationPopup({ className, setOpen }) {
	const isTablet = useIsTablet();
	const [currentMenu, setCurrentMenu] = useState([]);
	const [goingBack, setGoingBack] = useState(false);
	const menuRef = useRef();

	let menuToRender = mobileNavData;
	if (currentMenu.length) {
		// get the menu from the deep tree
		currentMenu.forEach(i => {
			menuToRender = menuToRender.navs[i];
		});
	}

	// create the back button
	const backButton = (
		<button
			className="hostjane-mobile-popup__menunavback"
			type="button"
			onClick={e => {
				e.preventDefault();
				setGoingBack(true);
				const newCurrentMenu = [...currentMenu];
				newCurrentMenu.pop();
				setCurrentMenu(newCurrentMenu);
			}}
		>
			<Icon title="Go back">
				<ChevronLeftIcon />
			</Icon>
		</button>
	);

	const currentMenuTitle = (
		<div className="hostjane-mobile-popup__browse">
			{menuToRender.title}
		</div>
	);

	const listKey = currentMenu.length ? currentMenu.join('-') : 'root';

	useEffect(() => {
		return () => {
			document.body.classList.remove('hostjane-mobile-menu-open');
		};
	}, []);

	return (
		<SmallNavigationPopupContainer
			className={className}
			isTablet={isTablet}
			goingBack={goingBack}
		>
			<div className="hostjane-mobile-popup__inner">
				<div className="hostjane-mobile-popup__header">
					{isTablet ? (
						<HostJaneLogoLink
							to="/"
							className="hostjane-mobile-popup__logo"
							width={225}
						/>
					) : (
						<>
							{currentMenu.length ? backButton : null}
							{currentMenuTitle}
						</>
					)}
					<button
						className="hostjane-mobile-popup__close"
						type="button"
						onClick={e => {
							e.preventDefault();
							setOpen(false);
						}}
					>
						<Icon title="Close menu">
							<CloseIcon />
						</Icon>
					</button>
				</div>
				{isTablet ? (
					<div className="hostjane-mobile-popup__tabletpager">
						{currentMenu.length ? backButton : null}
						{currentMenuTitle}
					</div>
				) : null}
				<div className="hostjane-mobile-popup__menu" ref={menuRef}>
					<TransitionGroup component={null}>
						<CSSTransition
							key={listKey}
							classNames="hostjane-mobile-popup__list"
							timeout={200}
							unmountOnExit
							onEnter={() => {
								if (menuRef.current) {
									menuRef.current.scrollTop = 0;
								}
							}}
							onExited={() => {
								setGoingBack(false);
							}}
						>
							<div className="hostjane-mobile-popup__list">
								{/* <div className="hostjane-mobile-popup__menuinner"> */}
								{menuToRender.navs.map((nav, index) => {
									const hasSubtree = nav.navs !== undefined;
									const key = `${listKey}-${index}`;

									// If this is a subtree, then use a button
									if (hasSubtree) {
										return (
											<button
												key={key}
												type="button"
												className="hostjane-mobile-popup__listitem"
												onClick={e => {
													e.preventDefault();
													const newCurrentMenu = [
														...currentMenu,
														index,
													];
													setCurrentMenu(
														newCurrentMenu
													);
												}}
											>
												<span className="hostjane-mobile-popup__listitemtitle">
													{nav.title}
												</span>
												<Icon className="hostjane-mobile-popup__listitemnext">
													<ChevronRightIcon />
												</Icon>
											</button>
										);
									}

									const navClassName = classNames(
										'hostjane-mobile-popup__listitem',
										`hostjane-mobile-popup__listitem--${
											nav.type || 'regular'
										}`,
										{
											'hostjane-mobile-popup__listitem--strong':
												nav.strong,
											'hostjane-mobile-popup__listitem--hasicon':
												nav.icon,
										}
									);

									const navChild = (
										<>
											{nav.icon ? (
												<span className="hostjane-mobile-popup__listitemicon">
													<nav.icon />
												</span>
											) : null}
											<span className="hostjane-mobile-popup__listitemtitle">
												{nav.title}
											</span>
											{nav.type === 'footerButton' ? (
												<span className="hostjane-mobile-popup__listitemmore">
													<ArrowRightIcon />
												</span>
											) : null}
										</>
									);

									// else use Link to a, based on whether this is internal or not
									if (nav.internal) {
										return (
											<Link
												key={key}
												to={nav.link}
												className={navClassName}
												onClick={() => {
													document.body.classList.remove(
														'hostjane-mobile-menu-open'
													);
												}}
											>
												{navChild}
											</Link>
										);
									}

									return (
										<a
											key={key}
											href={nav.link}
											className={navClassName}
										>
											{navChild}
										</a>
									);
								})}
								{/* </div> */}
							</div>
						</CSSTransition>
					</TransitionGroup>
				</div>
			</div>
		</SmallNavigationPopupContainer>
	);
}

export default function SmallNavigation() {
	const [open, setOpen] = useState(false);
	const isTablet = useIsTablet();

	useEffect(() => {
		if (typeof window !== 'undefined') {
			if (open && !isTablet) {
				document.body.classList.add('hostjane-mobile-menu-open');
			} else {
				document.body.classList.remove('hostjane-mobile-menu-open');
			}
		}
	}, [open, isTablet]);

	return (
		<MobileNavHeader isTablet={isTablet}>
			<NavContainer className="hostjane-nav">
				<button
					className="hostjane-nav__menutoggle"
					type="button"
					onClick={e => {
						e.preventDefault();
						setOpen(s => !s);
					}}
				>
					<Icon title="Open menu">
						<HamburgerIcon role="presentation" alt="HostJane" />
					</Icon>
				</button>
				<HostJaneLogoLink
					to="/"
					className="hostjane-nav__logo"
					width={190}
				/>
				<NavDirectLinkGroup className="hostjane-nav__links">
					{mobileNavData.primaryLinks.map((link, index) =>
						link.children && link.children.length ? (
							<NavDirectLinkWithChildren
								highlighted={link.highlighted}
								key={index}
								icon={link.icon}
								title={link.title}
								items={link.children}
								popupTitle={link.popupTitle}
								noDecoration={link.noDecoration}
								notShowOnMobile={link.notShowOnMobile}
							/>
						) : (
							<NavDirectLink
								
								
								
								
								
							>
								
								
							</NavDirectLink>
						)
					)}
				</NavDirectLinkGroup>
			</NavContainer>
			<NavSearch showCategory={false} />
			<CSSTransition
				unmountOnExit
				in={open}
				timeout={200}
				classNames="hostjane-mobile-popup"
			>
				<SmallNavigationPopup setOpen={setOpen} />
			</CSSTransition>
		</MobileNavHeader>
	);
}
