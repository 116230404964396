import React from 'react';
import styled from 'styled-components';

import SmallNavigation from './SmallNavigation';
import LargeNavigation from './LargeNavigation';

const MobileNavWrapper = styled.div``;
const DesktopNavWrapper = styled.div``;

const NavWrapper = styled.div`
	${MobileNavWrapper} {
		display: block;
	}
	${DesktopNavWrapper} {
		display: none;
	}
	@media screen and (min-width: ${props =>
			props.theme.siteApp.breakpoints.desktop}px) {
		${MobileNavWrapper} {
			display: none;
		}
		${DesktopNavWrapper} {
			display: block;
		}
	}
`;

export default function MainNaviagtion({ landingPage }) {
	return (
		<NavWrapper>
			<MobileNavWrapper>
				<SmallNavigation />
			</MobileNavWrapper>
			<DesktopNavWrapper>
				<LargeNavigation landingPage={landingPage} />
			</DesktopNavWrapper>
		</NavWrapper>
	);
}
